

import React from 'react';

import BrandIcon from './BrandIcon';
import Button from '../elements/Button';

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <br />
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              Realizar seu sonho
              {' '}
              <br />
              é a nossa missão
            </p>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Localização
            </h1>
            <p className="text-lg text-gray-400 font-light">
              Alto, Teresópolis
            </p>
            <p className="text-lg text-gray-400 font-light">
              Rio de Janeiro, Brasil
            </p>
            <p className="text-lg text-gray-400 font-light">
              contato@nirnsoft.com
            </p>
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Redes Sociais
            </h1>
            <Button href="https://www.linkedin.com/company/nirnsoft-technologies/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              LinkedIn
            </Button>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2024 - Todos os direitos reservados - NirnSoft Technologies
          </p>
          <div className="flex-row">
            <p className="inline-block text-lg text-gray-400 font-light">
              Feito com &#x2764; por&nbsp;
            </p>
            <Button href="https://www.linkedin.com/company/nirnsoft-technologies/" type="link" target="_blank" className="text-lg text-theme-purple font-light" isExternal>
              Nirnsoft Technologies
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
