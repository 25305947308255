
import React from 'react';

import Button from '../elements/Button';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <img
    src="/assets/images/logo top.png"
    alt="NIRNSOFT"
    className="w-auto h-auto"
  />
    </Button>
  );
}
