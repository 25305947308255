
// Services Img Imports
import Web from '../assets/images/Services/Web.png';
import Mobile from '../assets/images/Services/Mobile.png';
import UIUX from '../assets/images/Services/Design.png';

// Portfolio Img Imports
import Delivery from '../assets/images/Portfolio/Delivery.png';
import Stream from '../assets/images/Portfolio/Catalogo.png';
import Freelance from '../assets/images/Portfolio/freelance.png';
import Consulting from '../assets/images/Portfolio/consulting.png';
import Surtido from '../assets/images/Portfolio/Surtido.png';
import ManagementApp from '../assets/images/Portfolio/ManagementApp.png';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import FlavioCunha from '../assets/images/Testimonials/FlavioCunha.jpeg';
import FranciscoTeix from '../assets/images/Testimonials/FranciscoTeix.jpeg';
import MarceloDias from '../assets/images/Testimonials/MarceloDias.jpeg';

// TeamMembers
import ROD from '../assets/images/TeamMembers/rodrigoDut.jpeg';
import JFA from '../assets/images/TeamMembers/JoaoAssis.jpeg';
import CONT from '../assets/images/TeamMembers/ThiagoIgnacio.jpeg';
import ADV from '../assets/images/TeamMembers/CarlosMagalhaes.jpeg';
import Frontend1 from '../assets/images/TeamMembers/FabioOliv.jpeg';
import Frontend2 from '../assets/images/TeamMembers/JulioBarc.jpeg';
import Backend1 from '../assets/images/TeamMembers/RobertoTavares.jpeg';
import Backend2 from '../assets/images/TeamMembers/HugoSantiago.jpeg';
import Mobile1 from '../assets/images/TeamMembers/Luiz.jpeg';
import Mobile2 from '../assets/images/TeamMembers/GuilhermeSampai.jpeg';
import RH from '../assets/images/TeamMembers/SimoneVieira.jpeg';
import Design from '../assets/images/TeamMembers/HenriquedaCruz.jpeg';

export const Services = [
  {
    title: 'Desenvolvimento Web',
    imageUrl: Web,
    animation: 'left',
  },
  {
    title: 'Desenvolvimento Mobile',
    imageUrl: Mobile,
    animation: 'up',
  },
  {
    title: 'Redesenho de aplicações legado',
    imageUrl: UIUX,
    animation: 'right',
  },
];

export const Portfolios = [
  {
    id: 'asd1293uasdads1',
    title: 'Delivery - Panamá',
    imageUrl: Delivery,
    type: 'App Mobile',
    responsibility: [
      'Desenvolvimento Mobile',
      'Flutter',
      'Kotlin',
      'Swift',
    ],
    //credit: 'https://dribbble.com/shots/15164950-Recruiting-app',
  },
  {
    id: 'asd1293uhjkhkjh2',
    title: 'Catálogo e reviews de mangás e HQs - Nova Zelândia',
    imageUrl: Stream,
    type: 'App Mobile',
    responsibility: [
      'Desenvolvimento Mobile',
      'Flutter',
      'Kotlin',
      'Swift',
    ],
   // credit: 'https://dribbble.com/shots/15276430-Stream',
  },
  {
    id: 'asd1293uvbvcbbd3',
    title: 'Serviços profissionais - Camarôes e Nigeria ',
    imageUrl: Freelance,
    type: 'App Mobile',
    responsibility: [
      'Desenvolvimento Mobile',
      'Flutter',
      'Kotlin',
      'Swift',
      'Javascript',
    ],
    //credit: 'https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept',
  },
  {
    id: 'asd1293ufgdfgs4',
    title: 'Site Institucional - Consultoria - EUA',
    imageUrl: Consulting,
    type: 'WEB App',
    responsibility: [
      'Desenvolvimento Web',
      'UI/UX Design',
      'HTML',
      'CSS',
      'PHP',
    ],
    //credit: 'https://dribbble.com/shots/15176338-Aura-Website-Main-Page',
  },
  
];

export const Advantages = [
  [{
    title: 'Insights',
    description: 'Estamos sempre buscando fornecer as soluções mais adequadas para atingir o seu objetivo.',
    imageUrl: Communicative,
  },
  {
    title: 'Transparência',
    description: 'Nos orgulhamos de sempre agir com clareza e honestidade. Realizar o seu sonho, seu projeto ou sua ideia é a nossa prioridade.',
    imageUrl: Management,
  }],
  [{
    title: 'Colaboração​',
    description: 'Contamos com uma equipe multidisciplinar e com vasta experiência para troca de ideias e solução de problemas.',
    imageUrl: Collaborative,
  },
  {
    title: 'Relacionamento',
    description: "Valorizamos a construção e o cultivo de nossas parcerias, nosso objetivo é ser uma etapa positiva em sua história.",
    imageUrl: Favorite,
  }],
];
export const Testimonials = [
  {
    id: 1,
    name: 'Flavio Cunha',
    company: 'Empresário, logística',
    testimoni: 'Agradeço ao pessoal da NirnSoft pela atenção e cuidado que tiveram com a gente, o atendimento faz a diferença!',
    imageUrl: FlavioCunha,
  },
  {
    id: 2,
    name: 'Marcelo Dias',
    company: 'Empresário, comércio automotivo',
    testimoni: 'Não gosto de nada enrolado, resolver as coisas rápido e de forma eficiente foi muito bom, parabéns pelo trabalho.',
    imageUrl: MarceloDias,
  },
  {
    id: 3,
    name: 'Francisco Teixeira',
    company: 'Empresário, comércio',
    testimoni: 'Me ajudaram muito a entender as necessidades no meu ramo, gostaria de ter falado antes, a gente que não é dessa área de informática acaba ficando sem muita informação pra negociar.',
    imageUrl: FranciscoTeix,
  },
];

export const TeamMembers = [
  {
    name: 'Rodrigo Dutra',
    position: 'Head de Operações',
    imageUrl: ROD,
  },
  {
    name: 'João Assis',
    position: 'Head de Desenvolvimento',
    imageUrl: JFA,
  },
  {
    name: 'Thiago Ignacio',
    position: 'Gerente Contábil',
    imageUrl: CONT,
  },
  {
    name: 'Carlos Magalhães',
    position: 'Jurídico',
    imageUrl: ADV,
  },
  {
    name: 'Fabio Oliveira',
    position: 'Desenvolvedor Front-end Senior',
    imageUrl: Frontend1,
  },
  {
    name: 'Julio Barcellos',
    position: 'Desenvolvedor Front-end Senior',
    imageUrl: Frontend2,
  },
  {
    name: 'Roberto Tavares',
    position: 'Desenvolvedor Back-end Senior',
    imageUrl: Backend1,
  },
  {
    name: 'Hugo Santiago',
    position: 'Desenvolvedor Back-end Senior',
    imageUrl: Backend2,
  },
  {
    name: 'Luiz Otávio',
    position: 'Desenvolvedor Mobile Senior',
    imageUrl: Mobile1,
  },
  {
    name: 'Guilherme Sampaio',
    position: 'Desenvolvedor Mobile Senior',
    imageUrl: Mobile2,
  },
  {
    name: 'Simone Vieira',
    position: 'Coordenadora de RH',
    imageUrl: RH,
  },
  {
    name: 'Henrique da Cruz',
    position: 'Lead Designer',
    imageUrl: Design,
  },
];
